import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Comments } from '../../components';
import { THUMB_TYPE_DISLIKE, THUMB_TYPE_LIKE } from '../../util/types';
import { createResourceLocatorString } from '../../util/routes';
import { useConfirmationModal } from '../../util/hooks';
import { CommentForm } from '../../forms';
import routeConfiguration from '../../routeConfiguration';

import css from './CommentsPage.module.css';

const MAX_VISIBLE_REVIEWS = 1;

const redirectToLoginPage = (location, history) => {
  const state = {
    from: `${location.pathname}${location.search}${location.hash}`,
  };

  const routes = routeConfiguration();
  history.push(createResourceLocatorString('LoginPage', routes), state);
};

const CommentsHeading = ({ comments }) => (
  <div className={css.commentsHeadingContainer}>
    <h2 className={css.commentsHeading}>
      <FormattedMessage
        id="SectionComments.commentsHeading"
        values={{
          count: comments.length,
        }}
      />
    </h2>
  </div>
);

const CommentSection = props => {
  const {
    comments,
    currentUser,
    currentUserReactions,
    fetchCommentsError,
    handleCommentLikeOrDislike,
    handleEditComment,
    handleDeleteComment,
    handleCommentSubmit,
    sendCommentInProgress,
    sendCommentError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    addCommentLikeInProgress,
    addCommentLikeError,
    addCommentDislikeInProgress,
    addCommentDislikeError,
  } = props;

  const showCommentForm = currentUser && !fetchCommentsError;
  const commentsClassName = showCommentForm && comments.length > 0 ? css.commentsFeed : undefined;
  return (
    <div className={css.sectionComments}>
      <CommentsHeading comments={comments} />
      {showCommentForm ? (
        <CommentForm
          id="CommentsPage.comments"
          currentUser={currentUser}
          sendCommentInProgress={sendCommentInProgress}
          sendCommentError={sendCommentError}
          onSubmit={handleCommentSubmit}
          onCancel={(values, form) => form.restart()}
        />
      ) : null}
      {fetchCommentsError ? (
        <p className={css.errorText}>
          <FormattedMessage id="ExperiencePage.commentsError" />
        </p>
      ) : null}
      <Comments
        className={commentsClassName}
        comments={comments}
        currentUser={currentUser}
        currentUserReactions={currentUserReactions}
        likeOrDislikeReviewInProgress={addCommentLikeInProgress || addCommentDislikeInProgress}
        likeOrDislikeReviewError={addCommentLikeError || addCommentDislikeError}
        editCommentInProgress={editCommentInProgress}
        editCommentError={editCommentError}
        deleteCommentInProgress={deleteCommentInProgress}
        deleteCommentError={deleteCommentError}
        handleCommentLikeOrDislike={handleCommentLikeOrDislike}
        handleEditComment={handleEditComment}
        handleDeleteComment={handleDeleteComment}
      />
    </div>
  );
};

const SectionComments = props => {
  const {
    intl,
    location,
    history,
    currentUser,
    currentUserReactions,
    comments,
    fetchCommentsError,
    sendCommentInProgress,
    sendCommentError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    addCommentLikeInProgress,
    addCommentLikeError,
    addCommentDislikeInProgress,
    addCommentDislikeError,
    onSendComment,
    onEditComment,
    onDeleteComment,
    onAddCommentLike,
    onAddCommentDislike,
  } = props;

  const { open: onOpenConfirmationModal, modalElement } = useConfirmationModal();

  const handleCommentLikeOrDislike = (type, reviewId) => {
    if (currentUser) {
      if (type === THUMB_TYPE_LIKE) {
        onAddCommentLike(reviewId);
      } else if (type === THUMB_TYPE_DISLIKE) {
        onAddCommentDislike(reviewId);
      } else {
        console.error(new Error('Unknown like or dislike type'), {
          likeOrDislikeType: type,
        });
      }
    } else {
      redirectToLoginPage(location, history);
    }
  };

  const handleDeleteComment = async commentId => {
    const confirm = await onOpenConfirmationModal({
      id: 'ExperiencePage.confirmationModal',
      title: intl.formatMessage({
        id: 'ExperiencePage.deleteCommentConfirmationModalTitle',
      }),
      description: intl.formatMessage({
        id: 'ExperiencePage.deleteCommentConfirmationModalDescription',
      }),
    });

    if (confirm) {
      onDeleteComment(commentId);
    }
  };

  const handleCommentSubmit = (values, form) => {
    const { comment } = values;
    onSendComment(comment).then(() => {
      form.restart();
    });
  };

  return (
    <>
      <CommentSection
        comments={comments}
        maxVisibleReviews={MAX_VISIBLE_REVIEWS}
        currentUser={currentUser}
        currentUserReactions={currentUserReactions}
        handleCommentSubmit={handleCommentSubmit}
        handleEditComment={(commentId, comment) => onEditComment(commentId, comment)}
        handleDeleteComment={handleDeleteComment}
        handleCommentLikeOrDislike={handleCommentLikeOrDislike}
        fetchCommentsError={fetchCommentsError}
        sendCommentInProgress={sendCommentInProgress}
        sendCommentError={sendCommentError}
        editCommentInProgress={editCommentInProgress}
        editCommentError={editCommentError}
        deleteCommentInProgress={deleteCommentInProgress}
        deleteCommentError={deleteCommentError}
        addCommentLikeInProgress={addCommentLikeInProgress}
        addCommentDislikeInProgress={addCommentDislikeInProgress}
        addCommentLikeError={addCommentLikeError}
        addCommentDislikeError={addCommentDislikeError}
      />
      {modalElement}
    </>
  );
};

export default SectionComments;
