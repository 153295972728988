import React from 'react';
import { array, bool, func, shape, string, number, arrayOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import { propTypes } from '../../util/types';
import { withViewport } from '../../util/contextHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { ensureListing, ensureUser, userDisplayNameAsString } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { richText } from '../../util/richText';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  sendComment,
  editComment,
  deleteComment,
  addCommentLike,
  addCommentDislike,
} from './CommentsPage.duck';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
  IconArrowHead,
  SectionRecentlyViewedListings,
} from '../../components';
import { TopbarContainer, NotFoundPage } from '../../containers';

// Sections
import SectionHero from './SectionHero';
import SectionComments from './SectionComments';
import SectionSimilarExperiences from './SectionSimilarExperiences';

import css from './CommentsPage.module.css';

const { UUID } = sdkTypes;

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const prepareSimilarExperiences = (listings, listingId) =>
  listings.filter(l => l.id.uuid !== listingId).slice(0, 4);

const CommentsPageComponent = props => {
  const {
    intl,
    history,
    location,
    params: rawParams,
    currentUserReactions,
    // UI
    scrollingDisabled,
    // Listing
    getListing,
    showListingError,
    // user(s)
    currentUser,
    // comments
    comments,
    sendCommentInProgress,
    sendCommentError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    addCommentLikeInProgress,
    addCommentDislikeInProgress,
    addCommentLikeError,
    addCommentDislikeError,
    onSendComment,
    onEditComment,
    onDeleteComment,
    onAddCommentLike,
    onAddCommentDislike,
    // listings
    similarExperiences,
    querySimilarExperiencesInProgress,
    querySimilarExperiencesError,
    recentlyViewedListings,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = props;

  const listingId = new UUID(rawParams.id);
  const currentListing = ensureListing(getListing(listingId));

  const txId = location?.state?.txId;

  const { description = '', title = '' } = currentListing.attributes;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const topbar = (
    <TopbarContainer desktopClassName={css.topbarDesktop} contentClassName={css.topbarContent} />
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    const errorTitle = intl.formatMessage({
      id: 'CommentsPage.errorLoadingListingTitle',
    });

    return (
      <Page title={errorTitle} scrollingDisabled={scrollingDisabled} currentPage="CommentsPage">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.errorText}>
              <FormattedMessage id="CommentsPage.errorLoadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="CommentsPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'CommentsPage.loadingListingTitle',
    });

    return (
      <Page title={loadingTitle} scrollingDisabled={scrollingDisabled} currentPage="CommentsPage">
        <LayoutSingleColumn className={css.pageRoot}>
          <LayoutWrapperTopbar>{topbar}</LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <p className={css.loadingText}>
              <FormattedMessage id="CommentsPage.loadingListingMessage" />
            </p>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer currentPage="CommentsPage" />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const listingImages = (listing, variantName) =>
    (listing.images || [])
      .map(image => {
        const variants = image.attributes.variants;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards combatility only
        const sizes = image.attributes.sizes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CommentsPage.schemaTitle' }, { title, siteTitle });

  const returnLink = txId ? (
    <NamedLink className={css.returnLink} name="OrderPage" params={{ id: txId }}>
      <IconArrowHead className={css.returnLinkIcon} direction="left" />
      <FormattedMessage id="CommentsPage.returnText" />
    </NamedLink>
  ) : (
    <NamedLink
      className={css.returnLink}
      name="ExperiencePage"
      params={{ id: listingId.uuid, slug: createSlug(title) }}
    >
      <IconArrowHead className={css.returnLinkIcon} direction="left" />
      <FormattedMessage id="CommentsPage.returnText" />
    </NamedLink>
  );

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      currentPage="CommentsPage"
      contentType="website"
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description: description,
        name: schemaTitle,
        image: schemaImages,
      }}
    >
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar className={css.pageTopbar}>{topbar}</LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div>
            <SectionHero title={title} richTitle={richTitle} listingId={listingId} />
            <div className={css.contentContainer}>
              <SectionComments
                intl={intl}
                location={location}
                history={history}
                comments={comments}
                currentUser={currentUser}
                currentUserReactions={currentUserReactions}
                isOwnListing={isOwnListing}
                sendCommentInProgress={sendCommentInProgress}
                sendCommentError={sendCommentError}
                editCommentInProgress={editCommentInProgress}
                editCommentError={editCommentError}
                deleteCommentInProgress={deleteCommentInProgress}
                deleteCommentError={deleteCommentError}
                addCommentLikeInProgress={addCommentLikeInProgress}
                addCommentLikeError={addCommentLikeError}
                addCommentDislikeInProgress={addCommentDislikeInProgress}
                addCommentDislikeError={addCommentDislikeError}
                onSendComment={comment => onSendComment(listingId, comment)}
                onEditComment={(commentId, comment) => onEditComment(listingId, commentId, comment)}
                onDeleteComment={commentId => onDeleteComment(listingId, commentId)}
                onAddCommentLike={commentId => onAddCommentLike(listingId, commentId)}
                onAddCommentDislike={commentId => onAddCommentDislike(listingId, commentId)}
              />
              <div className={css.asideContent}>
                <SectionSimilarExperiences
                  similarExperiences={prepareSimilarExperiences(similarExperiences, listingId.uuid)}
                  querySimilarExperiencesInProgress={querySimilarExperiencesInProgress}
                  querySimilarExperiencesError={querySimilarExperiencesError}
                />
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <SectionRecentlyViewedListings
            intl={intl}
            recentlyViewedListings={recentlyViewedListings}
            queryRecentlyViewedListingsInProgress={queryRecentlyViewedListingsInProgress}
            queryRecentlyViewedListingsError={queryRecentlyViewedListingsError}
          />
          <Footer currentPage="CommentsPage" />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

CommentsPageComponent.defaultProps = {
  // listing
  showListingInProgress: false,
  showListingError: null,

  // user(s)
  isAuthenticated: false,
  currentUser: null,

  // comments
  sendCommentInProgress: false,
  sendCommentError: null,
  editCommentInProgress: false,
  editCommentError: null,
  deleteCommentInProgress: false,
  deleteCommentError: null,
  addCommentLikeInProgress: false,
  addCommentLikeError: null,
  addCommentDislikeInProgress: false,
  addCommentDislikeError: null,

  // experience(s)
  querySimilarExperiencesInProgress: false,
  querySimilarExperiencesError: null,
  recentlyViewedListings: [],
  queryRecentlyViewedListingsInProgress: false,
  queryRecentlyViewedListingsError: null,

  filterConfig: config.custom.filters,
};

CommentsPageComponent.propTypes = {
  // UI
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,

  // listing
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  showListingError: propTypes.error,

  // comments
  comments: array.isRequired,
  sendCommentInProgress: bool.isRequired,
  sendCommentError: propTypes.error,
  editCommentInProgress: bool.isRequired,
  editCommentError: propTypes.error,
  deleteCommentInProgress: bool.isRequired,
  deleteCommentError: propTypes.error,
  addCommentLikeInProgress: bool.isRequired,
  addCommentLikeError: propTypes.error,
  addCommentDislikeInProgress: bool.isRequired,
  addCommentDislikeError: propTypes.error,
  onSendComment: func.isRequired,
  onEditComment: func.isRequired,
  onDeleteComment: func.isRequired,
  onAddCommentLike: func.isRequired,
  onAddCommentDislike: func.isRequired,

  // user(s)
  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,

  // experience(s)
  similarExperiences: arrayOf(propTypes.listing),
  querySimilarExperiencesInProgress: bool.isRequired,
  querySimilarExperiencesError: propTypes.error,
  recentlyViewedListings: arrayOf(propTypes.listing),
  queryRecentlyViewedListingsInProgress: bool.isRequired,
  queryRecentlyViewedListingsError: propTypes.error,

  params: shape({
    id: string.isRequired,
  }).isRequired,
  filterConfig: array,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    showListingInProgress,
    comments,
    fetchCommentsInProgress,
    fetchCommentsError,
    sendCommentInProgress,
    sendCommentError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    addCommentLikeInProgress,
    addCommentLikeError,
    addCommentDislikeInProgress,
    addCommentDislikeError,
  } = state.CommentsPage;
  const { experienceIds, queryExperiencesInProgress, queryExperiencesError } = state.ExperiencePage;
  const {
    currentUser,
    currentUserReactions,
    recentlyViewedListingIds,
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    currentUserReactions,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingInProgress,
    showListingError,
    comments,
    fetchCommentsInProgress,
    fetchCommentsError,
    sendCommentInProgress,
    sendCommentError,
    editCommentInProgress,
    editCommentError,
    deleteCommentInProgress,
    deleteCommentError,
    addCommentLikeInProgress,
    addCommentLikeError,
    addCommentDislikeInProgress,
    addCommentDislikeError,
    similarExperiences: experienceIds.map(id => getListing(id)),
    querySimilarExperiencesInProgress: queryExperiencesInProgress,
    querySimilarExperiencesError: queryExperiencesError,
    recentlyViewedListings: recentlyViewedListingIds.map(id => getListing(id)),
    queryRecentlyViewedListingsInProgress,
    queryRecentlyViewedListingsError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSendComment: (listingId, comment) => dispatch(sendComment(listingId, comment)),
  onEditComment: (listingId, commentId, comment) =>
    dispatch(editComment(listingId, commentId, comment)),
  onDeleteComment: (listingId, commentId) => dispatch(deleteComment(listingId, commentId)),
  onAddCommentLike: (listingId, commentId) => dispatch(addCommentLike(listingId, commentId)),
  onAddCommentDislike: (listingId, commentId) => dispatch(addCommentDislike(listingId, commentId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const CommentsPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CommentsPageComponent);

export default CommentsPage;
