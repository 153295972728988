import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink, IconArrowHead } from '../../components';

import css from './CommentsPage.module.css';

const SectionHero = props => {
  const { title, richTitle, listingId } = props;

  // title container
  const titleContainer = (
    <div className={css.titleContainer}>
      <h1 className={css.title}>{richTitle}</h1>
    </div>
  );

  const returnLink = (
    <NamedLink
      className={css.returnLink}
      name="ExperiencePage"
      params={{ id: listingId.uuid, slug: createSlug(title) }}
    >
      <IconArrowHead className={css.returnLinkIcon} direction="left" />
      <FormattedMessage id="CommentsPage.returnText" />
    </NamedLink>
  );

  // top content
  const topContent = <div className={css.topContent}>{returnLink}</div>;

  return (
    <div className={css.sectionHero}>
      <div className={css.heroContent}>
        {topContent}
        {titleContainer}
      </div>
    </div>
  );
};

export default SectionHero;
